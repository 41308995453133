import Link from "next/link";
import Icon, { IconName } from "../icon";

interface TileButtonProps {
  iconName: IconName;
  label: string;
  href: string;
}

export function TileButton({ iconName, label, href }: TileButtonProps) {
  return (
    <Link
      href={href}
      className="group flex flex-1 items-center gap-3 bg-white tablet:h-40 tablet:flex-col tablet:pt-3 desktop:h-auto"
    >
      <Icon
        className="size-16 flex-shrink-0 max-tablet:px-2 tablet:size-20"
        name={iconName}
      />
      <span className="flex h-full w-full items-center text-base font-extrabold max-tablet:pr-3 tablet:px-3 tablet:text-center tablet:group-hover:bg-primary-50 desktop:h-18">
        {label}
      </span>
    </Link>
  );
}
