import { twMerge } from "tailwind-merge";

import LinkSME from "@/app/_components/linkSME";

interface LandingPageLinkProps {
  children: React.ReactNode;
  href: string;
  className?: string;
}

const LandingPageLink: React.FC<LandingPageLinkProps> = ({
  children,
  href,
  className = "",
}) => (
  <LinkSME
    className={twMerge("font-extrabold underline", className)}
    href={href}
  >
    {children}
  </LinkSME>
);

export default LandingPageLink;
