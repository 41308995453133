import React, { ReactElement } from "react";
import { twMerge } from "tailwind-merge";
import { AnchorMenuProvider } from "@/app/_components/anchorMenu/useAnchorMenu";
import AnchorMenuItem from "@/app/_components/anchorMenu/AnchorMenuItem";

export interface AnchorMenuProps {
  children:
    | ReactElement<typeof AnchorMenuItem>
    | ReactElement<typeof AnchorMenuItem>[];
  className?: string;
  active?: string;
  sortable?: boolean;
  sortDirection?: "asc" | "desc" | undefined;
  onChange: (name: string, sortDirection?: "asc" | "desc" | undefined) => void;
  autoscroll?: boolean;
}

const AnchorMenu = ({
  children,
  className = "",
  active = "",
  sortable = false,
  sortDirection = undefined,
  onChange,
  autoscroll = true,
}: AnchorMenuProps) => (
  <div
    role="tablist"
    className={twMerge(
      "inline-flex w-full items-center gap-6 overflow-x-scroll bg-background scrollbar-hidden ",
      "laptop:gap-10 desktop:gap-12",
      className,
    )}
  >
    <AnchorMenuProvider
      value={{ active, sortable, sortDirection, onChange, autoscroll }}
    >
      {children}
    </AnchorMenuProvider>
  </div>
);

export default AnchorMenu;
