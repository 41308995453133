import Image, { StaticImageData } from "next/image";
import { twMerge } from "tailwind-merge";

interface TextWithImageComponentProps {
  src: {
    mobile: StaticImageData;
    desktop: StaticImageData;
  };
  children: React.ReactNode;
}
const TextWithImageComponent = ({
  src,
  children,
}: TextWithImageComponentProps) => (
  <div className="flex flex-col gap-y-3">
    <div
      className={twMerge(
        `flex items-center justify-center gap-x-5 pl-5 pr-5 pt-5
        tablet:justify-between
        laptop:gap-x-11 laptop:pl-0 laptop:pr-0 laptop:pt-0
        desktop:gap-x-16
        max:gap-x-20`,
        "bg-neutral-50 laptop:bg-white",
      )}
    >
      <Image
        className="block self-end justify-self-end laptop:hidden"
        src={src.mobile}
        alt="example"
        height={240}
      />
      <Image
        width={450}
        className="hidden laptop:block laptop:w-[28.5rem]"
        src={src.desktop}
        alt="example"
      />
      <div className="hidden w-1/2 self-start tablet:block tablet:w-2/3 ">
        <div className="pb-5 pt-1.5 laptop:pt-2">{children}</div>
      </div>
    </div>
    <div className="block tablet:hidden">{children}</div>
  </div>
);
export default TextWithImageComponent;
