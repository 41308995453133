import SimpleSection from "@/app/_components/landingPages/Section";

interface FAQSectionProps {
  children: React.ReactNode;
  headline: string;
  className?: string;
}

const FAQSection: React.FC<FAQSectionProps> = ({
  children,
  headline,
  className,
}) => (
  <SimpleSection
    className={className}
    eyebrowHeadline="FAQ"
    headline={headline}
  >
    <div className="flex flex-col gap-y-3">{children}</div>
  </SimpleSection>
);

export default FAQSection;
