import Link from "next/link";
import { twMerge } from "tailwind-merge";

interface LinkSMEProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  children: React.ReactNode;
  as?: "a" | "nextLink";
  className?: string;
}

export default function LinkSME({
  href,
  children,
  className = "",
  as = "nextLink",
  ...props
}: LinkSMEProps) {
  return as === "a" ? (
    <a
      href={href}
      {...props}
      className={twMerge(
        "text-primary-500 hover:text-secondary-500 hover:underline cosma:text-text-link",
        className,
      )}
    >
      {children}
    </a>
  ) : (
    <Link
      className={twMerge(
        "text-primary-500 hover:text-secondary-500 hover:underline cosma:text-text-link",
        className,
      )}
      href={href}
    >
      {children}
    </Link>
  );
}
