import Calosol from "@/app/_media/svg/partner_logos/calosol.svg";
import DaheimSolar from "@/app/_media/svg/partner_logos/daheim.svg";
import EnergieVersum from "@/app/_media/svg/partner_logos/energieversum.svg";
import Lichtblick from "@/app/_media/svg/partner_logos/lichtblick.svg";
import Otovo from "@/app/_media/svg/partner_logos/otovo.svg";
import Dz4 from "@/app/_media/svg/partner_logos/dz4.svg";
import Soly from "@/app/_media/svg/partner_logos/soly.svg";
import SveaSolar from "@/app/_media/svg/partner_logos/svea-solar.svg";
import Headline from "./Headline";

export default function OurPartners() {
  return (
    <section
      className="flex flex-col gap-y-6 bg-gray-50 px-4 py-6 text-bluegray
                 tablet:px-5 tablet:py-8
                 laptop:gap-y-8 laptop:px-8 laptop:py-12
                 desktop:gap-y-9
                 max:px-[7.5rem]"
    >
      <Headline type="h2">Ausgewählte Partner:</Headline>
      <div
        className="
          flex flex-wrap gap-x-7 gap-y-4
          tablet:gap-x-9 tablet:gap-y-5
          laptop:gap-x-24 laptop:gap-y-6
          desktop:flex-nowrap desktop:justify-between desktop:gap-x-0
        "
      >
        <Calosol className="h-8 tablet:h-9 laptop:h-10 desktop:h-9 max:h-10" />
        <DaheimSolar className="h-8 tablet:h-9 laptop:h-10 desktop:h-9 max:h-10" />
        <Dz4 className="h-8 tablet:h-9 laptop:h-10 desktop:h-9 max:h-10" />
        <EnergieVersum className="h-8 tablet:h-9 laptop:h-10 desktop:h-9 max:h-10" />
        <Lichtblick className="h-8 tablet:h-9 laptop:h-10 desktop:h-9 max:h-10" />
        <Otovo className="h-8 tablet:h-9 laptop:h-10 desktop:h-9 max:h-10" />
        <Soly className="h-8 tablet:h-9 laptop:h-10 desktop:h-9 max:h-10" />
        <SveaSolar className="h-8 tablet:h-9 laptop:h-10 desktop:h-9 max:h-10" />
      </div>
    </section>
  );
}
