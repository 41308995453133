"use client";

import Image from "next/image";
import { cn } from "@/lib/utils";

import { LinkOrAnchor } from "@/app/_components/link";

import Button from "@/app/_components/button";
import ChecklistItem from "@/app/_components/checklistItem/ChecklistItem";
import AdaptiveImage from "@/app/_components/AdaptiveImage/AdaptiveImage";
import { Tag } from "@/app/_components/tags";
import PVTablet from "./_media/PV-tablet.png";
import PVLaptop from "./_media/PV-laptop.png";
import PVDesktop from "./_media/PV-desktop.png";
import HPTablet from "./_media/HP-tablet.png";
import HPLaptop from "./_media/HP-laptop.png";
import HPDesktop from "./_media/HP-desktop.png";

import TeaserBackground from "./TeaserBackground";
import TeaserContent from "./TeaserContent";

interface TeaserProps {
  className?: string;
}

const Teaser: React.FC<TeaserProps> = ({ className }) => (
  <div
    className={cn(
      "flex flex-col gap-5 tablet:flex-row laptop:flex-col laptop:gap-8",
      className,
    )}
  >
    {/* Mobile */}
    <LinkOrAnchor href="/solarrechner/" className="tablet:hidden">
      <div className="flex h-[9.625rem] flex-row">
        <div className="flex  w-[16.75rem] shrink-0 flex-col items-start gap-3.5 bg-white px-4 py-5">
          <div className="flex flex-col gap-2 text-text">
            <ChecklistItem
              className="gap-x-1.5 text-xs font-semibold"
              variant="small"
            >
              Bis zu 30% sparen
            </ChecklistItem>
            <ChecklistItem
              className="gap-x-1.5 text-xs font-semibold"
              variant="small"
            >
              Finanztip Testsieger 2024: Bester Solaranlagen-Vergleich
            </ChecklistItem>
          </div>
          <Button variant="primary" className="self-stretch px-0">
            Solaranlagen vergleichen
          </Button>
        </div>
        <Image
          priority
          alt="PV anlage"
          width={300}
          className=" overflow-hidden object-cover"
          src={PVTablet}
        />
      </div>
    </LinkOrAnchor>

    <LinkOrAnchor href="/waermepumpenrechner/" className="tablet:hidden">
      <div className="flex h-[9.625rem] flex-row">
        <div className="flex  w-[16.75rem] shrink-0 flex-col items-start gap-3.5 bg-white px-4 py-5">
          <div className="flex flex-col gap-2 text-text">
            <ChecklistItem
              className="gap-x-1.5 text-xs font-semibold"
              variant="small"
            >
              In 2 Minuten passendes Wärmepumpen-Modell finden
            </ChecklistItem>
            <ChecklistItem
              className="gap-x-1.5 text-xs font-semibold"
              variant="small"
            >
              Kostenlos Anbieter anfragen
            </ChecklistItem>
          </div>
          <Button variant="primary" className="self-stretch px-0">
            Wärmepumpen vergleichen
          </Button>
        </div>
        <Image
          priority
          alt="Wärmepumpe"
          width={300}
          className="overflow-hidden object-cover object-[30%_0%]"
          src={HPTablet}
        />
      </div>
    </LinkOrAnchor>

    {/* tablet and up */}
    <LinkOrAnchor
      href="/solarrechner/"
      className="relative hidden h-64 max-laptop:flex-1 tablet:flex laptop:h-[196px] desktop:h-[226px]"
    >
      <TeaserBackground>
        <AdaptiveImage
          alt="Solaranlage auf einem Hausdach"
          imageClassName="object-cover"
          images={[
            { image: PVDesktop, size: "desktop" },
            { image: PVLaptop, size: "laptop" },
            { image: PVTablet, size: "tablet" },
          ]}
        />
      </TeaserBackground>
      <TeaserContent className="h-full">
        <Tag size="small" icon="sale">
          Bis zu 30% sparen
        </Tag>
        <Tag size="small" icon="trophy">
          Von Finanztip getestet: Bester Solaranlagen-Vergleich 2024!
        </Tag>
        <Button size="large" variant="primary">
          Solaranlagen vergleichen
        </Button>
      </TeaserContent>
    </LinkOrAnchor>

    <LinkOrAnchor
      href="/waermepumpenrechner/"
      className="relative hidden h-64 max-laptop:flex-1 tablet:flex laptop:h-[196px] desktop:h-[226px]"
    >
      <TeaserBackground>
        <AdaptiveImage
          alt="Wärmepumpe"
          imageClassName="object-cover object-[1%_1%]"
          images={[
            { image: HPDesktop, size: "desktop" },
            { image: HPLaptop, size: "laptop" },
            { image: HPTablet, size: "tablet" },
          ]}
        />
      </TeaserBackground>
      <TeaserContent className="h-full">
        <Tag icon="spark" size="small">
          Neu: Wärmepumpen-Rechner
        </Tag>
        <Button size="large" variant="primary">
          Wärmepumpen vergleichen
        </Button>
      </TeaserContent>
    </LinkOrAnchor>
  </div>
);

export default Teaser;
