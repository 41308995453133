import { twMerge } from "tailwind-merge";
import IconCard, { type IconCardProps } from "@/app/_components/iconCard";

const IconCardLandingPage: React.FC<IconCardProps> = ({
  children,
  className = "",
  ...props
}) => (
  <IconCard
    className={twMerge("flex-1 flex-row laptop:flex-col", className)}
    {...props}
  >
    {children}
  </IconCard>
);

export default IconCardLandingPage;
