"use client";

import classNames from "classnames";
import { forwardRef } from "react";

interface TeaserBackgroundProps {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const TeaserBackground = forwardRef<HTMLDivElement, TeaserBackgroundProps>(
  ({ className, children, style }, ref) => (
    <div
      ref={ref}
      className={classNames("absolute h-full w-full", className)}
      style={style}
    >
      <div className="relative h-full w-full">{children}</div>
    </div>
  ),
);

TeaserBackground.displayName = "TeaserBackground";

export default TeaserBackground;
