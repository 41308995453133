import { twMerge } from "tailwind-merge";

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export default function Card({ children, className = undefined }: CardProps) {
  return (
    <div
      className={twMerge(
        "bg-white p-0 shadow-none tablet:p-4 tablet:shadow-md",
        className,
      )}
    >
      {children}
    </div>
  );
}
