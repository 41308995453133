interface ChecklistCardProps {
  title: string;
  children: React.ReactNode;
}

const ChecklistCard: React.FC<ChecklistCardProps> = ({ title, children }) => (
  <div className="flex flex-col gap-y-2 bg-neutral-50 px-3 py-4 laptop:px-4 desktop:px-6 desktop:py-5">
    <span className="text-lg font-black laptop:text-xl desktop:text-2xl">
      {title}
    </span>
    {children}
  </div>
);

export default ChecklistCard;
