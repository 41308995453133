"use client";

import Button from "@/app/_components/button";
import Icon from "../icon";

export default function BackToTopButton() {
  return (
    <Button
      variant="secondary"
      aria-label="Zurück nach oben"
      className="!size-12 !bg-primary-500 !p-0"
      onPress={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <Icon name="arrow-up" className="size-6 text-white" />
    </Button>
  );
}
