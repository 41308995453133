"use client";

import { useSearchParams } from "next/navigation";
import { twMerge } from "tailwind-merge";

interface HeroHeadlinesProps {
  className?: string;
  title: string;
  subtitle: string;
}

export function HeroHeadlines({
  className = "",
  title: defaultTitle,
  subtitle: defaultSubtitle,
}: HeroHeadlinesProps) {
  const searchParams = useSearchParams();
  const title = searchParams.get("lpinsert-title") || defaultTitle;
  const subtitle = searchParams.get("lpinsert-subtitle") || defaultSubtitle;
  return (
    <div
      className={twMerge("flex flex-col gap-y-1.5 laptop:gap-y-2.5", className)}
    >
      <div className="hyphens-auto text-3xl font-black leading-9">{title}</div>
      <div className="text-lg font-semibold leading-7">{subtitle}</div>
    </div>
  );
}
