import AnchorMenu, {
  type AnchorMenuProps,
} from "@/app/_components/anchorMenu/AnchorMenu";

interface AnchorMenuLandingPagesProps extends AnchorMenuProps {}

const AnchorMenuLandingPages: React.FC<AnchorMenuLandingPagesProps> = ({
  children,
  active,
  onChange,
}) => (
  <AnchorMenu
    active={active}
    onChange={onChange}
    className="fixed top-12 z-90 max-w-screen-max px-4 tablet:top-14 tablet:px-5 laptop:top-16 laptop:px-8 max:px-[7.5rem]"
  >
    {children}
  </AnchorMenu>
);

export default AnchorMenuLandingPages;
