import { createContext, useContext } from "react";

interface IAnchorMenuContext {
  active: string;
  sortable?: boolean;
  sortDirection?: "asc" | "desc" | undefined;
  onChange: (name: string, sortDirection?: "asc" | "desc" | undefined) => void;
  autoscroll?: boolean;
}

const AnchorMenuContext = createContext<IAnchorMenuContext>({
  active: "",
  sortable: false,
  sortDirection: undefined,
  onChange: () => {},
  autoscroll: true,
});

export const AnchorMenuProvider = AnchorMenuContext.Provider;

export const useAnchorMenu = () => {
  const { active, sortable, sortDirection, onChange, autoscroll } =
    useContext(AnchorMenuContext);

  return { active, sortable, sortDirection, onChange, autoscroll };
};
