interface StepCardProps {
  eyebrowHeadline?: string;
  headline: string | React.ReactNode;
  headlineType?: "span" | "h3";
  children?: React.ReactNode;
  content?: React.ReactNode;
}

const StepCard: React.FC<StepCardProps> = ({
  headline,
  headlineType = "span",
  eyebrowHeadline = undefined,
  content = undefined,
  children = undefined,
}) => (
  <div className="flex flex-col gap-y-2 bg-neutral-50 p-3 laptop:gap-y-2.5 laptop:p-4 desktop:p-6">
    <div className="flex flex-col gap-y-0.5 desktop:gap-y-1">
      <span className="text-sm font-black text-text-700 laptop:text-base desktop:text-lg">
        {eyebrowHeadline}
      </span>
      {headlineType === "span" && (
        <span className="text-lg font-black laptop:text-xl desktop:text-2xl">
          {headline}
        </span>
      )}
      {headlineType === "h3" && (
        <h3 className="text-lg font-black laptop:text-xl desktop:text-2xl">
          {headline}
        </h3>
      )}
    </div>
    {content && <span className="text-base font-normal">{content}</span>}
    {children}
  </div>
);

export default StepCard;
