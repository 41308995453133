import * as React from "react";
import { cn } from "@/lib/utils";
import { useCarousel } from "../hooks/useCarousel";

interface CarouselContentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

export const CarouselContent = React.forwardRef<
  HTMLDivElement,
  CarouselContentProps
>(({ className, children }, ref) => {
  const { carouselRef } = useCarousel();
  return (
    <div
      ref={carouselRef}
      className={cn("-ml-8 -mr-4 flex flex-col overflow-hidden", className)}
    >
      {children}
    </div>
  );
});
CarouselContent.displayName = "CarouselContent";
