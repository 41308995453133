import { cn } from "@/lib/utils";

import Icon, { type IconName } from "../icon";
import Card from "../content/card";
import { RoundedIcon } from "../icon/roundedIcon";

export interface IconCardProps {
  children: React.ReactNode;
  icon: IconName;
  headline?: string;
  className?: string;
  orientation?: "column" | "row";
}

export default function IconCard({
  icon,
  children,
  orientation = "column",
  headline = undefined,
  className = undefined,
}: IconCardProps) {
  return (
    <Card
      className={cn(
        "flex items-start gap-x-3 gap-y-1.5 p-3 shadow-sm tablet:flex-row laptop:flex-col laptop:gap-y-3 laptop:px-4 laptop:py-5 desktop:px-6",
        {
          "flex-row": orientation === "row",
          "flex-col": orientation === "column",
        },
        className,
      )}
    >
      <RoundedIcon>
        <Icon
          name={icon}
          className="h-5 w-5 text-tertiary-500 laptop:h-6 laptop:w-6"
        />
      </RoundedIcon>
      <div className="gap-y-1 pt-1 text-base laptop:gap-y-1.5 laptop:pt-0">
        {headline && (
          <div className="pb-1.5 text-lg font-black laptop:text-xl">
            {headline}
          </div>
        )}
        {children}
      </div>
    </Card>
  );
}
