interface IconCardWrapperProps {
  children: React.ReactNode;
}

const IconCardWrapper: React.FC<IconCardWrapperProps> = ({ children }) => (
  <div className="flex flex-col gap-y-5 laptop:flex-row laptop:gap-x-6">
    {children}
  </div>
);

export default IconCardWrapper;
