import Image from "next/image";
import tuvBadge from "@/public/tuev-webcheck-badge.png";
import { cn } from "@/lib/utils";

const SocialProof = ({
  hideRatingsOnMobile = true,
  showFinanztipRecommendation = true,
}: {
  hideRatingsOnMobile?: boolean;
  showFinanztipRecommendation?: boolean;
}) => (
  <div className="flex flex-col gap-4 desktop:gap-6">
    <div className="flex flex-shrink-0 items-center gap-4 pr-4 desktop:gap-6 desktop:pr-6">
      <Image
        className="h-12 w-12 laptop:h-14 laptop:w-14"
        width={120}
        height={120}
        src={tuvBadge}
        alt="TÜV Saarland Webcheck"
      />

      <div
        className={cn("w-px self-stretch bg-bluegray-300 tablet:block", {
          hidden: hideRatingsOnMobile,
        })}
      />
      {showFinanztipRecommendation && (
        <div className="flex w-fit text-sm font-black leading-6 tablet:text-base">
          Finanztip hat unseren Solar-Vergleich getestet und empfiehlt!
        </div>
      )}
    </div>
    {/* Google Reviews LAPTOP and up */}
    <div
      className={cn("hidden flex-grow laptop:flex", {
        hidden: hideRatingsOnMobile,
      })}
    >
      <div className="reviewforest-app-daeee7b0-649f-46d8-aecf-0f3f9d4adb89" />
    </div>
    {/* Google Reviews TABLET, lp-social-proof is used for globals.css styling */}
    <div
      className={cn(
        "lp-social-proof hidden flex-grow tablet:flex laptop:hidden",
        {
          hidden: hideRatingsOnMobile,
        },
      )}
    >
      <div className="reviewforest-app-daeee7b0-649f-46d8-aecf-0f3f9d4adb89" />
    </div>
  </div>
);

export default SocialProof;
