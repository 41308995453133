"use client";

import Section from "@/app/_components/homepage/section";
import Image, { StaticImageData } from "next/image";

import { Carousel } from "@/app/_components/Carousel/Carousel";
import { CarouselContent } from "@/app/_components/Carousel/CarouselContent/CarouselContent";
import { CarouselItem } from "@/app/_components/Carousel/CarouselItem/CarouselItem";
import PageControlElement2 from "@/app/_components/Carousel/PageControl/PageControlElement2";
import { PageControlController } from "@/app/_components/Carousel/PageControlController/PageControlController";

import {
  FeatureTile,
  FeatureTileStep,
  FeatureTileTextContent,
  FeatureTileTitle,
} from "@/app/_components/landingPages/FeatureTile";
import {
  SectionEyebrowHeadline,
  SectionHeader,
  SectionHeadline,
  SectionSubHeadline,
} from "../landingPages/Section";
import MockDataMobile from "./_media/Mobile-Mock-Daten.png";
import MockSubsidiesMobile from "./_media/Mobile-Mock-Foerderung.png";
import MockContactMobile from "./_media/Mobile-Mock-Kontakt.png";
import MockCompareMobile from "./_media/Mobile-Mock-Vergleich.png";

import Steps from "./steps";
import { IconName } from "../icon";
import { CarouselItems } from "../Carousel/CarouselItems";
import { useCarousel } from "../Carousel/hooks/useCarousel";

export type StepData = {
  image: StaticImageData;
  title: string;
  iconName: IconName;
};

const PageControls: React.FC<{ size: number; stepData: StepData[] }> = ({
  size,
  stepData,
}) => {
  const { scrollTo, selectedIndex, scrollSnaps } = useCarousel();
  return (
    <PageControlController size={size}>
      {scrollSnaps.map((_, index) => (
        <PageControlElement2
          key={stepData[index].title}
          index={index}
          onClick={() => scrollTo(index)}
          isActive={index === selectedIndex}
        />
      ))}
    </PageControlController>
  );
};

export default function HowItWorks() {
  const stepData: StepData[] = [
    {
      image: MockDataMobile,
      title: "Einfache Fragen beantworten",
      iconName: "requests",
    },
    {
      image: MockCompareMobile,
      title: "Angebote, Leistung & Preise vergleichen",
      iconName: "compare",
    },
    {
      image: MockSubsidiesMobile,
      title: "Aktuelle Fördermöglichkeiten abrufen",
      iconName: "grant",
    },
    {
      image: MockContactMobile,
      title: "Fachbetriebe kostenlos & unverbindlich anfragen",
      iconName: "portal",
    },
  ];
  return (
    <Section className="flex w-full flex-col gap-6">
      <SectionHeader>
        <SectionEyebrowHeadline>So funktioniert’s</SectionEyebrowHeadline>
        <SectionHeadline>
          In 4 Schritten zum besten Photovoltaik- oder Wärmepumpen-Angebot
        </SectionHeadline>
        <SectionSubHeadline className="pt-2 laptop:pt-3.5">
          Mit den kostenlosen Online-Tools vergleichen Sie Anbieter in Ihrer
          Nähe.
        </SectionSubHeadline>
      </SectionHeader>

      {/* MOBILE VIEW - carousel */}
      <Carousel
        className="tablet:hidden"
        orientation="horizontal"
        opts={{ slidesToScroll: "auto" }}
      >
        <CarouselContent className="!-ml-0 !-mr-0">
          <CarouselItems className="!ml-0">
            {stepData.map((item, index) => (
              <CarouselItem key={item.image.src}>
                <FeatureTile className="grow">
                  <FeatureTileTextContent>
                    <FeatureTileStep>{`0${index + 1}`}</FeatureTileStep>
                    <FeatureTileTitle>{item.title}</FeatureTileTitle>
                  </FeatureTileTextContent>
                  <Image
                    className="max-w-[50%] shrink self-end"
                    src={item.image}
                    alt="example"
                    height={242}
                  />
                </FeatureTile>
              </CarouselItem>
            ))}
          </CarouselItems>
          <PageControls size={stepData.length} stepData={stepData} />
        </CarouselContent>
      </Carousel>

      {/* TABLET VIEW */}
      <div className="hidden flex-col gap-5 tablet:flex laptop:hidden">
        <div className="flex gap-5">
          {stepData.slice(0, 2).map((item, index) => (
            <FeatureTile className="flex-1 grow" key={item.image.src}>
              <Image
                className="max-w-[50%] shrink self-end"
                src={item.image}
                alt="example"
                height={144}
              />
              <FeatureTileTextContent>
                <FeatureTileStep className="text-lg">
                  0{index + 1}
                </FeatureTileStep>
                <FeatureTileTitle className="text-base font-semibold">
                  {item.title}
                </FeatureTileTitle>
              </FeatureTileTextContent>
            </FeatureTile>
          ))}
        </div>
        <div className="flex gap-5">
          {stepData.slice(2, 4).map((item, index) => (
            <FeatureTile className="flex-1 grow" key={item.image.src}>
              <Image
                className="max-w-[50%] shrink self-end"
                src={item.image}
                alt="example"
                height={144}
              />
              <FeatureTileTextContent>
                <FeatureTileStep className="text-lg">
                  0{index + 3}
                </FeatureTileStep>
                <FeatureTileTitle className="text-base font-semibold">
                  {item.title}
                </FeatureTileTitle>
              </FeatureTileTextContent>
            </FeatureTile>
          ))}
        </div>
      </div>

      {/* DESKTOP VIEW */}
      <Steps stepData={stepData} className="hidden laptop:flex" />
    </Section>
  );
}
