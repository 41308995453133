"use client";

import { useEffect, useState, useRef, useCallback } from "react";
import throttle from "lodash.throttle";

import TeaserBackground from "@/app/(SME)/(SME-main)/_components/Hero/Teaser/TeaserBackground";
import AdaptiveImage from "@/app/_components/AdaptiveImage/AdaptiveImage";

import ImageMobile from "./_media/second-cta-mobile.png";
import ImageTablet from "./_media/second-cta-tablet.png";
import ImageLaptop from "./_media/second-cta-laptop.png";
import {
  SectionHeader,
  SectionHeadline,
  SectionSubHeadline,
} from "../landingPages/Section";
import { TileButton } from "../landingPages/tileButton";

export default function SecondCTASection() {
  const sectionRef = useRef<HTMLDivElement>(null);
  const teaserRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const getInitialPadding = () => {
    if (typeof window === "undefined") return 0;

    if (window.innerWidth >= 1600) {
      return 7.5;
    }
    if (window.innerWidth >= 1024) {
      return 2;
    }
    return 0;
  };

  const [padding, setPadding] = useState(getInitialPadding());
  const [viewportWidth, setViewportWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0,
  );

  // avoid missmatch between ssr and client (layout switch)
  // in order to have the correct initial padding we use tailwind classes on the section and the teaser
  // after initial render we add the dynamic padding via style.padding
  useEffect(() => {
    if (teaserRef.current) {
      teaserRef.current.style.padding = `0 ${padding}rem`;
    }
    if (contentRef.current) {
      contentRef.current.style.padding = `0 ${padding * (viewportWidth >= 1600 ? 0.15625 : 0.625)}rem`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = useCallback(() => {
    if (!sectionRef.current || !teaserRef.current || !contentRef.current)
      return;

    const teaserRect = teaserRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const newViewportWidth = window.innerWidth;
    setViewportWidth(newViewportWidth);

    // Calculate when teaser is in middle of viewport
    const teaserMiddle = teaserRect.top + teaserRect.height / 2;
    const viewportMiddle = viewportHeight / 2;

    // Start transition when first half of teaser becomes visible
    const transitionStart = viewportHeight - teaserRect.height / 4;
    const transitionEnd = viewportMiddle;

    let defaultPadding = 0;
    if (newViewportWidth >= 1600) {
      defaultPadding = 7.5;
    } else if (newViewportWidth >= 1024) {
      defaultPadding = 2;
    }

    let newPadding = defaultPadding;
    if (teaserMiddle <= transitionStart && teaserMiddle >= transitionEnd) {
      const progress =
        (teaserMiddle - transitionEnd) / (transitionStart - transitionEnd);
      newPadding = defaultPadding * progress;
    } else if (teaserMiddle > transitionStart) {
      newPadding = defaultPadding;
    } else if (teaserMiddle < transitionEnd) {
      newPadding = 0;
    }

    setPadding(newPadding);
    teaserRef.current.style.padding = `0 ${newPadding}rem`;
    contentRef.current.style.padding = `0 ${newPadding * (newViewportWidth >= 1600 ? 0.15625 : 0.625)}rem`;
  }, []);

  useEffect(() => {
    const throttledScroll = throttle(handleScroll, 32);
    const throttledResize = throttle(handleScroll, 32);

    requestAnimationFrame(() => {
      handleScroll();
    });

    window.addEventListener("scroll", throttledScroll);
    window.addEventListener("resize", throttledResize);

    return () => {
      window.removeEventListener("scroll", throttledScroll);
      window.removeEventListener("resize", throttledResize);
    };
  }, [handleScroll]);

  return (
    <section
      ref={sectionRef}
      className="relative z-0 flex h-[528px] w-full flex-col justify-end gap-6 bg-neutral-50 px-4 pb-12 tablet:h-[444px] tablet:px-5 laptop:h-[458px] laptop:px-8 desktop:h-[562px] max:px-[7.5rem]"
    >
      <div
        ref={contentRef}
        className="transition-padding z-60 transition-all duration-300 ease-out max-tablet:px-0 tablet:px-5"
      >
        <div className="flex flex-grow-0 flex-col gap-5 tablet:w-1/2 laptop:w-[460px]">
          <SectionHeader>
            <SectionHeadline className="text-white">
              Bereit für die Energiewende?
            </SectionHeadline>
            <SectionSubHeadline className="hidden pt-2 text-white tablet:block laptop:pt-2.5">
              Möchten Sie Strom oder Wärme umweltfreundlich direkt aus Ihrer
              Umgebung gewinnen? Entdecken Sie jetzt die besten Lösungen für Ihr
              Zuhause.
            </SectionSubHeadline>
          </SectionHeader>
          <div className="flex flex-col gap-4 tablet:flex-row tablet:gap-6">
            <TileButton
              href="/solarrechner/"
              iconName="solar-panel"
              label="Solaranlagen vergleichen"
            />
            <TileButton
              href="/waermepumpenrechner/"
              iconName="heatpump-4"
              label="Wärmepumpen vergleichen"
            />
          </div>
        </div>
      </div>
      <TeaserBackground
        ref={teaserRef}
        className="transition-padding bottom-0 left-0 transition-all duration-300 ease-out max-laptop:px-0 laptop:px-8 max:px-[7.5rem]"
      >
        <AdaptiveImage
          alt="Solaranlage auf einem Hausdach"
          imageClassName="object-cover w-full h-full"
          images={[
            { image: ImageLaptop, size: "laptop" },
            { image: ImageTablet, size: "tablet" },
            { image: ImageMobile, size: "mobile" },
          ]}
        />
      </TeaserBackground>
    </section>
  );
}
