import { Suspense } from "react";
import { twMerge } from "tailwind-merge";
import Image, { StaticImageData } from "next/image";

import TeaserImage from "@/app/_components/TeaserImage/TeaserImage";
import SocialProof from "@/app/_components/landingPages/SocialProof";
import TeaserTag from "@/app/(SME)/(SME-main)/_components/Hero/Teaser/TeaserTag";
import TeaserTags from "@/app/(SME)/(SME-main)/_components/Hero/Teaser/TeaserTags";
import { ButtonLink } from "@/app/_components/link";
import AdaptiveImage, {
  AdaptiveImageType,
} from "@/app/_components/AdaptiveImage/AdaptiveImage";
import { HeroHeadlines } from "./HeroHeadlines";

interface ImageSet {
  mobile: StaticImageData;
  tablet: StaticImageData;
  laptop: StaticImageData;
  desktop: StaticImageData;
  max: StaticImageData;
}

interface Tags {
  text: string;
  key: string;
}
export interface HeroProps {
  title: string;
  subtitle: string;
  tags?: Tags[];
  imageSrc?: string;
  href?: string;
  buttonText?: string;
  imageClassnameLaptopAndUp?: string;
  imageClassnameMobile?: string;
  imageSet?: ImageSet;
  images?: AdaptiveImageType[];
  hideRatingsOnMobile?: boolean;
  showFinanztipRecommendation?: boolean;
}

const PaddingClassNames =
  "px-4 tablet:pl-5 tablet:pr-0 laptop:pl-8 max:pl-[120px]";

export const Hero = ({
  title,
  href = "/solarrechner/",
  buttonText = "Preise vergleichen",
  tags = undefined,
  imageSrc = undefined,
  subtitle,
  imageClassnameLaptopAndUp = undefined,
  imageClassnameMobile = undefined,
  imageSet = undefined,
  images = undefined,
  hideRatingsOnMobile = undefined,
  showFinanztipRecommendation = undefined,
}: HeroProps) => (
  <div className="relative h-full gap-x-6 bg-cyan-950 text-white tablet:grid tablet:grid-cols-2 laptop:gap-x-11 desktop:gap-x-16 max:gap-x-20 max:pr-[120px]">
    <div className="flex flex-col gap-y-6  py-6 tablet:gap-y-5 laptop:gap-y-8 laptop:py-12 desktop:gap-y-10 max:gap-y-12">
      <div className="flex flex-col gap-y-4 desktop:gap-y-6">
        <div className={PaddingClassNames}>
          <Suspense>
            <HeroHeadlines title={title} subtitle={subtitle} />
          </Suspense>
        </div>
        <div className="relative h-[228px] tablet:h-fit">
          <div className="absolute h-full w-full tablet:hidden">
            {images && (
              <AdaptiveImage images={images} className="object-cover" />
            )}
            {imageSrc && (
              <TeaserImage
                alt="landing page image"
                fill
                src={imageSrc}
                className={twMerge("object-[5%_5%]", imageClassnameMobile)}
              />
            )}
            {imageSet && (
              <TeaserImage
                fill
                alt="landing page image"
                src={imageSet.mobile.src}
              />
            )}
          </div>
          <div
            className={twMerge(
              "flex h-full flex-col justify-center gap-y-6 tablet:gap-y-5 laptop:gap-y-8 desktop:gap-y-6",
              PaddingClassNames,
            )}
          >
            {tags && (
              <TeaserTags>
                {tags.map(tag => (
                  <TeaserTag
                    className="bg-primary-50 tablet:bg-white"
                    key={tag.key}
                  >
                    {tag.text}
                  </TeaserTag>
                ))}
              </TeaserTags>
            )}
            <ButtonLink
              href={href}
              className="w-fit"
              size="large"
              variant="primary"
            >
              {buttonText}
            </ButtonLink>
          </div>
        </div>
      </div>
      <div className={PaddingClassNames}>
        <SocialProof
          hideRatingsOnMobile={hideRatingsOnMobile}
          showFinanztipRecommendation={showFinanztipRecommendation}
        />
      </div>
    </div>
    <div className="hidden h-full w-full tablet:relative tablet:block">
      {images && <AdaptiveImage images={images} className="object-cover" />}
      {imageSrc && (
        <TeaserImage
          fill
          alt="landing page image"
          src={imageSrc}
          className={twMerge(
            "tablet:object-[80%_21%] laptop:object-[90%_10%]",
            imageClassnameLaptopAndUp,
          )}
        />
      )}
      {imageSet && (
        <>
          <Image
            priority
            fill
            className="hidden object-contain max:block"
            src={imageSet.max.src}
            alt="solarrechner teaser"
          />
          <Image
            priority
            fill
            className="hidden h-fit object-cover desktop:block max:hidden"
            src={imageSet.desktop.src}
            alt="solarrechner teaser"
          />
          <Image
            priority
            fill
            className="hidden h-fit object-cover tablet:block laptop:hidden"
            src={imageSet.tablet.src}
            alt="solarrechner teaser"
          />
          <Image
            priority
            fill
            className="hidden h-fit object-cover laptop:block desktop:hidden"
            src={imageSet.laptop.src}
            alt="solarrechner teaser"
          />
        </>
      )}
    </div>
  </div>
);
