import * as React from "react";
import { cn } from "@/lib/utils";
import { useCarousel } from "@/app/_components/Carousel/hooks/useCarousel";

interface CarouselItemsProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

export const CarouselItems = React.forwardRef<
  HTMLDivElement,
  CarouselItemsProps
>(({ className, children, ...props }, ref) => {
  const { orientation } = useCarousel();
  return (
    <div
      ref={ref}
      className={cn(
        "flex gap-5 first:ml-8 last:mr-8 tablet:gap-8",
        orientation === "horizontal" ? "" : " flex-col",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});
CarouselItems.displayName = "CarouselItems";
