import { cn } from "@/lib/utils";
import React, { forwardRef } from "react";

interface ScrollToSectionWrapperProps {
  id?: string;
  children: React.ReactNode;
  className?: string;
}

const ScrollToSectionWrapper: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ScrollToSectionWrapperProps
> = ({ id = undefined, children, className }, ref) => (
  <div id={id} ref={ref} className={cn("scroll-mt-28", className)}>
    {children}
  </div>
);

export default forwardRef(ScrollToSectionWrapper);
