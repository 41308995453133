import * as React from "react";
import { useCarousel } from "@/app/_components/Carousel/hooks/useCarousel";
import { cn } from "@/lib/utils";

export const PageControlController = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<any>
>(({ className, size, item, children, ...props }, ref) => {
  const { scrollSnaps } = useCarousel();

  if (scrollSnaps.length === 1) {
    return <div className="flex h-[2.1rem] grow gap-1 laptop:gap-2" />;
  }
  return (
    <div className="flex min-h-[2.1rem] flex-row justify-center">
      <div
        className={cn(
          "flex min-h-[2rem] w-full items-center justify-center gap-1 tablet:gap-3.5 laptop:gap-2",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
});

PageControlController.displayName = "PageControlController";
