import Icon from "../icon";

interface ChecklistCardItemProps {
  children: React.ReactNode;
}

const ChecklistCardItem: React.FC<ChecklistCardItemProps> = ({ children }) => (
  <div className="flex gap-x-2 py-1">
    <Icon name="check" className="size-6 flex-shrink-0 text-primary-500" />
    {children}
  </div>
);

export default ChecklistCardItem;
