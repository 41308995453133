import Br from "@/app/_media/svg/logo_br-sw.svg";
import DieZeit from "@/app/_media/svg/logo_die-zeit-sw.svg";
import Focus from "@/app/_media/svg/logo_focus-sw.svg";
import Handelsblatt from "@/app/_media/svg/logo_handelsblatt-sw.svg";
import Mdr from "@/app/_media/svg/logo_mdr-sw.svg";
import Ntv from "@/app/_media/svg/logo_ntv-sw.svg";
import SpiegelOnline from "@/app/_media/svg/logo_so-sw.svg";
import Stern from "@/app/_media/svg/logo_stern-sw.svg";
import Wdr2 from "@/app/_media/svg/logo_wdr2-sw.svg";

const MediaLogos = () => (
  <div className="flex flex-col gap-x-16 gap-y-5 bg-gray-50 p-4 px-4 pb-6 pt-4 text-bluegray-600 tablet:px-5 tablet:pb-8 tablet:pt-6 laptop:flex-row laptop:px-8 laptop:py-12 max:px-[7.5rem]">
    <span className="whitespace-nowrap font-semibold leading-6 desktop:hidden ">
      Bekannt aus
    </span>
    <div className="flex w-full flex-wrap justify-between gap-x-9 gap-y-6 tablet:justify-normal desktop:justify-between desktop:gap-0 ">
      <span className="hidden whitespace-nowrap font-semibold leading-6 desktop:inline-block">
        Bekannt aus
      </span>
      <Br className="hidden h-5 tablet:block" />
      <DieZeit className="hidden h-5 tablet:block" />
      <Focus className="hidden h-5 tablet:block" />
      <Handelsblatt className="h-5" />
      <Mdr className="h-5" />
      <Ntv className="h-5" />
      <SpiegelOnline className="hidden h-5 tablet:block" />
      <Stern className="hidden h-5 tablet:block" />
      <Wdr2 className="hidden h-5 tablet:block" />
    </div>
  </div>
);

export default MediaLogos;
