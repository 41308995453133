"use client";

import { FAQSection, FAQExpandable } from "@/app/_components/landingPages";

export default function FaqSection() {
  return (
    <FAQSection
      className="w-full"
      headline="Häufige Fragen zum Preisvergleich von PV und Wärmepumpen"
    >
      <FAQExpandable
        defaultOpen={false}
        title="Was ist der Solar- bzw. Wärmepumen-Rechner?"
      >
        Mit den innovativen Online-Tools vergleichen Sie kostenlos Solaranlagen
        und Wärmepumpen unterschiedlicher Montage- und Herstellerfirmen. Sie
        geben einfach einige Daten über Ihr Eigenheim an und erhalten nicht nur
        eine Wirtschaftlichkeitsanalyse der Angebote in Ihrer Region, sondern
        auch eine Liste mit möglichen Förderprogrammen. So können Sie eine
        fundierte Entscheidung für die geplante energetische Modernisierung
        treffen.
      </FAQExpandable>

      <FAQExpandable
        defaultOpen={false}
        title="Welche Angaben zur Immobilie werden abgefragt?"
      >
        Sie beantworten generelle Fragen zu Ihrem Einfamilienhaus und Ihrem
        Stromverbrauch. Für den Standort ist lediglich Ihre PLZ entscheidend.
      </FAQExpandable>

      <FAQExpandable
        defaultOpen={false}
        title="Wie wähle ich eine Solar- oder Wärmepumpenfirma aus?"
      >
        Sie vergleichen die Anschaffungskosten, die Leistung der Anlage, die
        Amortisationsdauer und die Garantiejahre der Anlagen. Anschließend
        entscheiden Sie, ob Sie Ihre Kontaktdaten unverbindlich an eine oder
        mehrere Firmen weitergeben möchten, um von diesen kontaktiert zu werden.
      </FAQExpandable>

      <FAQExpandable
        defaultOpen={false}
        title="Wie übermittle ich meine Immobiliendaten an die Anbieter?"
      >
        Sie geben über das Kontaktformular Ihre E-Mail-Adresse, Ihre
        Telefonnummer und Ihre Wohnadresse an. Nach Bestätigung Ihrer
        E-Mail-Adresse erhalten Fachbetriebe Ihre Immobiliendaten.
      </FAQExpandable>
    </FAQSection>
  );
}
