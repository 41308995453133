import { twMerge } from "tailwind-merge";

import { iconNames } from "@/app/_media/icons";

import Archive from "@/app/_media/icons/icon-archive.svg";
import ArrowLeft from "@/app/_media/icons/icon-arrow-left.svg";
import ArrowRight from "@/app/_media/icons/icon-arrow-right.svg";
import ArrowBottomRight from "@/app/_media/icons/icon-arrow-down-right.svg";
import Ascending from "@/app/_media/icons/icon-ascending.svg";
import Attention from "@/app/_media/icons/icon-attention.svg";
import Autarchy from "@/app/_media/icons/icon-autarchy.svg";
import Authority from "@/app/_media/icons/icon-authority.svg";
import Battery from "@/app/_media/icons/icon-battery.svg";
import Calendar from "@/app/_media/icons/icon-calendar.svg";
import Call from "@/app/_media/icons/icon-call.svg";
import Check from "@/app/_media/icons/icon-check.svg";
import ChevronDown from "@/app/_media/icons/icon-chevron-down.svg";
import ChevronLeft from "@/app/_media/icons/icon-chevron-left.svg";
import ChevronRight from "@/app/_media/icons/icon-chevron-right.svg";
import ChevronUp from "@/app/_media/icons/icon-chevron-up.svg";
import Close from "@/app/_media/icons/icon-close.svg";
import Co2 from "@/app/_media/icons/icon-co2.svg";
import Compare from "@/app/_media/icons/icon-compare.svg";
import Contract from "@/app/_media/icons/icon-contract.svg";
import CopyToClipboard from "@/app/_media/icons/icon-copy-to-clipboard.svg";
import Delete from "@/app/_media/icons/icon-delete.svg";
import Descending from "@/app/_media/icons/icon-descending.svg";
import Download from "@/app/_media/icons/icon-download.svg";
import DownloadCosma from "@/app/_media/icons/icon-download-cosma.svg";
import Duration from "@/app/_media/icons/icon-duration.svg";
import Edit from "@/app/_media/icons/icon-edit.svg";
import Email from "@/app/_media/icons/icon-email.svg";
import ElectricAppliances from "@/app/_media/icons/icon-electric-appliances.svg";
import Electricity from "@/app/_media/icons/icon-electricity.svg";
import EnergyConsumption from "@/app/_media/icons/icon-energy-consumption.svg";
import EnergySource from "@/app/_media/icons/icon-energy-source.svg";
import FeedInRate from "@/app/_media/icons/icon-feed-in-rate.svg";
import Filter from "@/app/_media/icons/icon-filter.svg";
import Grant from "@/app/_media/icons/icon-grant.svg";
import Help from "@/app/_media/icons/icon-help.svg";
import HouseIntersection from "@/app/_media/icons/icon-house-intersection.svg";
import Heatpump from "@/app/_media/icons/icon-heatpump.svg";
import Heatpump2 from "@/app/_media/icons/icon-heatpump-2.svg";
import Heatpump3 from "@/app/_media/icons/icon-heatpump-3.svg";
import ImageIcon from "@/app/_media/icons/icon-image.svg";
import Images from "@/app/_media/icons/icon-images.svg";
import Info from "@/app/_media/icons/icon-info.svg";
import Investment from "@/app/_media/icons/icon-investment.svg";
import Knowledge from "@/app/_media/icons/icon-knowledge.svg";
import LastPage from "@/app/_media/icons/icon-last-page.svg";
import LastUpdate from "@/app/_media/icons/icon-last-update.svg";
import Legal from "@/app/_media/icons/icon-legal.svg";
import Linkout from "@/app/_media/icons/icon-linkout.svg";
import LongTerm from "@/app/_media/icons/icon-longterm.svg";
import List from "@/app/_media/icons/icon-list.svg";
import Location from "@/app/_media/icons/icon-location.svg";
import Maintenance from "@/app/_media/icons/icon-maintenance.svg";
import Map from "@/app/_media/icons/icon-map.svg";
import Menu from "@/app/_media/icons/icon-menu.svg";
import MoreOption from "@/app/_media/icons/icon-more-option.svg";
import OwnerOccupant from "@/app/_media/icons/icon-owner-occupant.svg";
import Pdf from "@/app/_media/icons/icon-pdf.svg";
import Plus from "@/app/_media/icons/icon-plus.svg";
import Portal from "@/app/_media/icons/icon-portal.svg";
import PriceAlarm from "@/app/_media/icons/icon-price-alarm.svg";
import QuestionMark from "@/app/_media/icons/icon-questionmark.svg";
import Quote from "@/app/_media/icons/icon-quote.svg";
import Requests from "@/app/_media/icons/icon-requests.svg";
import Resize from "@/app/_media/icons/icon-resize.svg";
import Savings from "@/app/_media/icons/icon-savings.svg";
import Search from "@/app/_media/icons/icon-search.svg";
import Settings from "@/app/_media/icons/icon-settings.svg";
import Solar from "@/app/_media/icons/icon-solar.svg";
import SortDefault from "@/app/_media/icons/icon-sort-default.svg";
import Sort from "@/app/_media/icons/icon-sort.svg";
import StarEmpty from "@/app/_media/icons/icon-star-empty.svg";
import StarFull from "@/app/_media/icons/icon-star-full.svg";
import StarHalf from "@/app/_media/icons/icon-star-half.svg";
import Stats from "@/app/_media/icons/icon-stats.svg";
import Timer from "@/app/_media/icons/icon-timer.svg";
import Trophy from "@/app/_media/icons/icon-trophy.svg";
import Tree from "@/app/_media/icons/icon-tree.svg";
import Upload from "@/app/_media/icons/icon-upload.svg";
import UserProfile from "@/app/_media/icons/icon-user-profile.svg";
import VideoCall from "@/app/_media/icons/icon-video-call.svg";
import Warning from "@/app/_media/icons/icon-warning.svg";
import Web from "@/app/_media/icons/icon-web.svg";
import Logout from "@/app/_media/icons/icon-logout.svg";
import ElectricCar from "@/app/_media/icons/icon-electric-car.svg";
import Wallbox from "@/app/_media/icons/icon-wallbox.svg";
import Direction from "@/app/_media/icons/icon-direction.svg";
import Roof from "@/app/_media/icons/icon-roof.svg";
import Maximize from "@/app/_media/icons/icon-maximize.svg";
import Sun from "@/app/_media/icons/icon-sun.svg";
import ArrowUp from "@/app/_media/icons/icon-arrow-up.svg";
import Alarm from "@/app/_media/icons/icon-alarm.svg";
import Moon from "@/app/_media/icons/icon-moon.svg";
import HeatpumpTypeAirWater from "@/app/_media/icons/icon-heatpump-type-air-water.svg";
import HeatpumpTypeAirAir from "@/app/_media/icons/icon-heatpump-type-air-air.svg";
import HeatpumpTypeHybrid from "@/app/_media/icons/icon-heatpump-type-hybrid.svg";
import HeatpumpTypeGround from "@/app/_media/icons/icon-heatpump-type-ground.svg";
import HeatpumpVariantMonoIndoors from "@/app/_media/icons/icon-heatpump-variant-mono-indoors.svg";
import HeatpumpVariantMonoOutdoors from "@/app/_media/icons/icon-heatpump-variant-mono-outdoors.svg";
import HeatpumpVariantSplit from "@/app/_media/icons/icon-heatpump-variant-split.svg";
import Sale from "@/app/_media/icons/icon-sale.svg";
import Spark from "@/app/_media/icons/icon-spark.svg";
import CheckCircle from "@/app/_media/icons/icon-check-circle.svg";
import Lead from "@/app/_media/icons/icon-lead.svg";
import Gas from "@/app/_media/icons/icon-gas.svg";
import Announcement from "@/app/_media/icons/icon-announcement.svg";
import Heatpump4 from "@/app/_media/icons/icon-heatpump-4.svg";
import SolarPanel from "@/app/_media/icons/icon-solar-panels.svg";
import Team from "@/app/_media/icons/icon-team.svg";

export type IconName = (typeof iconNames)[number];

export interface IconProps {
  name: IconName;
  className?: string;
}

export default function Icon({ name, className = undefined }: IconProps) {
  function getIcon(name: IconName, className?: string) {
    switch (name) {
      case "archive":
        return <Archive className={twMerge("size-8", className)} />;
      case "arrow-left":
        return <ArrowLeft className={twMerge("size-8", className)} />;
      case "arrow-right":
        return <ArrowRight className={twMerge("size-8", className)} />;
      case "arrow-down-right":
        return <ArrowBottomRight className={twMerge("size-8", className)} />;
      case "ascending":
        return <Ascending className={twMerge("size-8", className)} />;
      case "attention":
        return <Attention className={twMerge("size-8", className)} />;
      case "autarchy":
        return <Autarchy className={twMerge("size-8", className)} />;
      case "authority":
        return <Authority className={twMerge("size-8", className)} />;
      case "battery":
        return <Battery className={twMerge("size-8", className)} />;
      case "calendar":
        return <Calendar className={twMerge("size-8", className)} />;
      case "call":
        return <Call className={twMerge("size-8", className)} />;
      case "check":
        return <Check className={twMerge("size-8", className)} />;
      case "chevron-down":
        return <ChevronDown className={twMerge("size-8", className)} />;
      case "chevron-left":
        return <ChevronLeft className={twMerge("size-8", className)} />;
      case "chevron-right":
        return <ChevronRight className={twMerge("size-8", className)} />;
      case "chevron-up":
        return <ChevronUp className={twMerge("size-8", className)} />;
      case "close":
        return <Close className={twMerge("size-8", className)} />;
      case "co2":
        return <Co2 className={twMerge("size-8", className)} />;
      case "compare":
        return <Compare className={twMerge("size-8", className)} />;
      case "contract":
        return <Contract className={twMerge("size-8", className)} />;
      case "copy-to-clipboard":
        return <CopyToClipboard className={twMerge("size-8", className)} />;
      case "delete":
        return <Delete className={twMerge("size-8", className)} />;
      case "duration":
        return <Duration className={twMerge("size-8", className)} />;
      case "descending":
        return <Descending className={twMerge("size-8", className)} />;
      case "download":
        return <Download className={twMerge("size-8", className)} />;
      case "download-cosma":
        return <DownloadCosma className={twMerge("size-8", className)} />;
      case "edit":
        return <Edit className={twMerge("size-8", className)} />;
      case "email":
        return <Email className={twMerge("size-8", className)} />;
      case "electric-appliances":
        return <ElectricAppliances className={twMerge("size-8", className)} />;
      case "electric-car":
        return <ElectricCar className={twMerge("size-8", className)} />;
      case "electricity":
        return <Electricity className={twMerge("size-8", className)} />;
      case "energy-consumption":
        return <EnergyConsumption className={twMerge("size-8", className)} />;
      case "energy-source":
        return <EnergySource className={twMerge("size-8", className)} />;
      case "feed-in-rate":
        return <FeedInRate className={twMerge("size-8", className)} />;
      case "filter":
        return <Filter className={twMerge("size-8", className)} />;
      case "grant":
        return <Grant className={twMerge("size-8", className)} />;
      case "help":
        return <Help className={twMerge("size-8", className)} />;
      case "heatpump":
        return <Heatpump className={twMerge("size-8", className)} />;
      case "house-intersection":
        return <HouseIntersection className={twMerge("size-8", className)} />;
      case "heatpump-2":
        return <Heatpump2 className={twMerge("size-8", className)} />;
      case "heatpump-3":
        return <Heatpump3 className={twMerge("size-8", className)} />;
      case "heatpump-4":
        return <Heatpump4 className={twMerge("size-8", className)} />;
      case "image":
        return <ImageIcon className={twMerge("size-8", className)} />;
      case "images":
        return <Images className={twMerge("size-8", className)} />;
      case "info":
        return <Info className={twMerge("size-8", className)} />;
      case "investment":
        return <Investment className={twMerge("size-8", className)} />;
      case "knowledge":
        return <Knowledge className={twMerge("size-8", className)} />;
      case "last-page":
        return <LastPage className={twMerge("size-8", className)} />;
      case "last-update":
        return <LastUpdate className={twMerge("size-8", className)} />;
      case "legal":
        return <Legal className={twMerge("size-8", className)} />;
      case "linkout":
        return <Linkout className={twMerge("size-8", className)} />;
      case "list":
        return <List className={twMerge("size-8", className)} />;
      case "location":
        return <Location className={twMerge("size-8", className)} />;
      case "longterm":
        return <LongTerm className={twMerge("size-8", className)} />;
      case "maintenance":
        return <Maintenance className={twMerge("size-8", className)} />;
      case "map":
        return <Map className={twMerge("size-8", className)} />;
      case "menu":
        return <Menu className={twMerge("size-8", className)} />;
      case "more-option":
        return <MoreOption className={twMerge("size-8", className)} />;
      case "owner-occupant":
        return <OwnerOccupant className={twMerge("size-8", className)} />;
      case "pdf":
        return <Pdf className={twMerge("size-8", className)} />;
      case "plus":
        return <Plus className={twMerge("size-8", className)} />;
      case "price-alarm":
        return <PriceAlarm className={twMerge("size-8", className)} />;
      case "requests":
        return <Requests className={twMerge("size-8", className)} />;
      case "resize":
        return <Resize className={twMerge("size-8", className)} />;
      case "savings":
        return <Savings className={twMerge("size-8", className)} />;
      case "search":
        return <Search className={twMerge("size-8", className)} />;
      case "settings":
        return <Settings className={twMerge("size-8", className)} />;
      case "solar":
        return <Solar className={twMerge("size-8", className)} />;
      case "sort-default":
        return <SortDefault className={twMerge("size-8", className)} />;
      case "sort":
        return <Sort className={twMerge("size-8", className)} />;
      case "star-empty":
        return <StarEmpty className={twMerge("size-8", className)} />;
      case "star-full":
        return <StarFull className={twMerge("size-8", className)} />;
      case "star-half":
        return <StarHalf className={twMerge("size-8", className)} />;
      case "stats":
        return <Stats className={twMerge("size-8", className)} />;
      case "timer":
        return <Timer className={twMerge("size-8", className)} />;
      case "tree":
        return <Tree className={twMerge("size-8", className)} />;
      case "trophy":
        return <Trophy className={twMerge("size-8", className)} />;
      case "upload":
        return <Upload className={twMerge("size-8", className)} />;
      case "user-profile":
        return <UserProfile className={twMerge("size-8", className)} />;
      case "warning":
        return <Warning className={twMerge("size-8", className)} />;
      case "web":
        return <Web className={twMerge("size-8", className)} />;
      case "logout":
        return <Logout className={twMerge("size-8", className)} />;
      case "wallbox":
        return <Wallbox className={twMerge("size-8", className)} />;
      case "direction":
        return <Direction className={twMerge("size-8", className)} />;
      case "roof":
        return <Roof className={twMerge("size-8", className)} />;
      case "maximize":
        return <Maximize className={twMerge("size-8", className)} />;
      case "sun":
        return <Sun className={twMerge("size-8", className)} />;
      case "arrow-up":
        return <ArrowUp className={twMerge("size-8", className)} />;
      case "alarm":
        return <Alarm className={twMerge("size-8", className)} />;
      case "portal":
        return <Portal className={twMerge("size-8", className)} />;
      case "question-mark":
        return <QuestionMark className={twMerge("size-8", className)} />;
      case "quote":
        return <Quote className={twMerge("size-8", className)} />;
      case "video-call":
        return <VideoCall className={twMerge("size-8", className)} />;
      case "moon":
        return <Moon className={twMerge("size-8", className)} />;
      case "heatpumpTypeAirWater":
        return (
          <HeatpumpTypeAirWater className={twMerge("size-8", className)} />
        );
      case "heatpumpTypeAirAir":
        return <HeatpumpTypeAirAir className={twMerge("size-8", className)} />;
      case "heatpumpTypeHybrid":
        return <HeatpumpTypeHybrid className={twMerge("size-8", className)} />;
      case "heatpumpTypeGround":
        return <HeatpumpTypeGround className={twMerge("size-8", className)} />;
      case "heatpumpVariantMonoIndoors":
        return (
          <HeatpumpVariantMonoIndoors
            className={twMerge("size-8", className)}
          />
        );
      case "heatpumpVariantMonoOutdoors":
        return (
          <HeatpumpVariantMonoOutdoors
            className={twMerge("size-8", className)}
          />
        );
      case "heatpumpVariantSplit":
        return (
          <HeatpumpVariantSplit className={twMerge("size-8", className)} />
        );
      case "sale":
        return <Sale className={twMerge("size-8", className)} />;
      case "spark":
        return <Spark className={twMerge("size-8", className)} />;
      case "check-circle":
        return <CheckCircle className={twMerge("size-8", className)} />;
      case "lead":
        return <Lead className={twMerge("size-8", className)} />;
      case "gas":
        return <Gas className={twMerge("size-8", className)} />;
      case "announcement":
        return <Announcement className={twMerge("size-8", className)} />;
      case "solar-panel":
        return <SolarPanel className={twMerge("size-8", className)} />;
      case "team":
        return <Team className={twMerge("size-8", className)} />;
      default:
        return null; // Or handle the default case as needed
    }
  }
  return getIcon(name, className);
}
