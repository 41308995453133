import Image, { StaticImageData } from "next/image";
import Link from "next/link";

import { cn } from "@/lib/utils";
import Tag from "../tags/Tag";
import Icon from "../icon";

export interface ArticleTeaserProps {
  image?: string | StaticImageData;
  imageAlt: string;
  title: string;
  description?: string;
  tags?: string[];
  timeToRead?: number;
  linkTo: string;
  className: string;
}
const ArticleTeaser = ({
  image,
  imageAlt,
  title,
  description,
  tags,
  timeToRead,
  linkTo,
  className,
}: ArticleTeaserProps) => {
  if (tags && tags.length > 4) {
    throw new Error("Maximum 4 Tags are allowed");
  }
  return (
    <Link
      href={linkTo}
      className={cn(
        "group inline-flex grow cursor-pointer flex-col items-center justify-start gap-5 rounded-sm shadow-xl",
        "laptop:max-w-[28rem] max:max-w-[29rem]",
        className,
      )}
    >
      <div
        className="
      flex grow flex-col items-start justify-start self-stretch bg-white
     "
      >
        <div className="relative inline-flex h-52 w-full shrink-0 items-center justify-center overflow-hidden laptop:h-60">
          <Image
            fill
            alt={imageAlt}
            src={image as string}
            className="inline-flex items-center justify-center object-cover transition-all duration-500 group-hover:scale-[1.14]"
          />
        </div>
        <div
          className="flex grow flex-col items-start justify-start gap-3.5 self-stretch pb-4 pl-4 pr-4 pt-5
                     laptop:gap-4 laptop:pb-8 laptop:pl-6 laptop:pr-6 laptop:pt-6"
        >
          <div className="flex flex-col items-start justify-start gap-1.5 self-stretch">
            <div className="text-xl font-black leading-7 text-text-1000 laptop:text-2xl laptop:leading-[2rem] ">
              {title}
            </div>
            {description && (
              <div className="laptop:text-semibold shrink-0 self-stretch text-base font-semibold leading-normal text-text-1000 laptop:text-lg laptop:leading-7 ">
                {description}
              </div>
            )}
          </div>
          <div
            className={cn(
              "flex w-full flex-grow flex-col items-end justify-between",
              { "items-end": !timeToRead },
            )}
          >
            {tags && (
              <div className="mb-4 flex flex-wrap items-start justify-start gap-x-1.5 gap-y-2 self-stretch laptop:gap-x-2.5 laptop:gap-y-2">
                {tags.map(value => (
                  <Tag
                    size="xs"
                    className="text-text-1000 laptop:gap-2 laptop:px-2 laptop:py-1 laptop:text-sm"
                    key={value}
                  >
                    {value}
                  </Tag>
                ))}
              </div>
            )}
            <div className="flex flex-grow items-center justify-between gap-1.5 rounded-sm laptop:items-start">
              {timeToRead && (
                <div
                  className="
                  flex items-center gap-1.5 text-xs font-semibold leading-none text-slate-500
                  laptop:text-sm laptop:leading-normal"
                >
                  <Icon
                    name="duration"
                    className="relative h-4 w-4 laptop:h-5 laptop:w-5"
                  />
                  <span>Lesedauer {timeToRead} Minuten</span>
                </div>
              )}
              <div className="flex justify-end laptop:hidden">
                <div className="flex h-11 w-11 items-center justify-center border-2 border-primary-500 bg-primary-500 hover:bg-primary-700">
                  <Icon
                    name="arrow-right"
                    className="relative size-6 text-white"
                  />
                </div>
              </div>
            </div>
            <div className="hidden items-center justify-end gap-3 text-base font-extrabold leading-normal text-primary-500 hover:text-primary-700 laptop:flex">
              <div>Artikel lesen</div>
              <Icon name="arrow-right" className="relative size-6 " />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default ArticleTeaser;
