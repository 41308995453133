import { forwardRef } from "react";

import { Hero, type HeroProps } from "@/app/_components/landingPages/Hero/Hero";
import MediaLogos from "@/app/_components/landingPages/MediaLogos";
import OurPartners from "@/app/_components/landingPages/OurPartners";
import VideoSection from "@/app/_components/landingPages/VideoSection";

import Icon from "../icon";

interface LandingPageWrapperProps extends HeroProps {
  children: React.ReactNode;
  register?: () => (section: HTMLElement | null) => void;
  videoSectionTitleAsH1?: boolean;
  withVideoSection?: boolean;
  hideRatingsOnMobile?: boolean;
  showFinanztipRecommendation?: boolean;
}

const LandingPageWrapper: React.ForwardRefRenderFunction<
  HTMLDivElement,
  LandingPageWrapperProps
> = (
  {
    children,
    title,
    subtitle,
    tags,
    imageSrc,
    register = undefined,
    href = undefined,
    buttonText = "Preise vergleichen",
    imageClassnameLaptopAndUp,
    imageClassnameMobile,
    videoSectionTitleAsH1 = false,
    imageSet,
    images,
    withVideoSection = true,
    hideRatingsOnMobile = undefined,
    showFinanztipRecommendation = undefined,
  },
  ref,
) => (
  <main ref={ref} className="text-text">
    <div className="my-10 flex flex-col gap-y-12 laptop:gap-y-16 desktop:gap-y-20">
      <div>
        <Hero
          href={href}
          buttonText={buttonText}
          title={title}
          subtitle={subtitle}
          tags={tags}
          imageSrc={imageSrc}
          imageClassnameLaptopAndUp={imageClassnameLaptopAndUp}
          imageClassnameMobile={imageClassnameMobile}
          imageSet={imageSet}
          images={images}
          hideRatingsOnMobile={hideRatingsOnMobile}
          showFinanztipRecommendation={showFinanztipRecommendation}
        />
        <MediaLogos />
      </div>
      {withVideoSection && (
        <>
          <VideoSection
            titleAsH1={videoSectionTitleAsH1}
            ref={register ? register() : undefined}
          />
          <OurPartners />
        </>
      )}
      {children}
    </div>
    <div className="flex justify-end pr-4 tablet:pr-5 laptop:pr-8 max:pr-[120px]">
      <button
        className="bg-primary-500 p-2.5 laptop:p-3 desktop:p-4"
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
        }
      >
        <Icon name="arrow-up" className="size-6 " />
      </button>
    </div>
  </main>
);

export default forwardRef(LandingPageWrapper);
