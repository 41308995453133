import Image from "next/image";
import classNames from "classnames";

interface TeaserImageProps extends React.ComponentProps<typeof Image> {
  alt: string;
  className?: string;
}

const TeaserImage: React.FC<TeaserImageProps> = ({
  className,
  alt,
  ...props
}) => (
  <Image
    priority
    alt={alt}
    className={classNames(
      "absolute h-full w-full object-cover transition-all duration-400 ease-out",
      className,
    )}
    {...props}
  />
);

export default TeaserImage;
