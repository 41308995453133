import React, { forwardRef } from "react";
import WithYoutubeConsent from "@/app/_components/hoc/withYoutubeConsent";
import Headline from "@/app/_components/landingPages/Headline";
import ScrollToSectionWrapper from "@/app/_components/landingPages/ScrollToSectionWrapper";

interface VideoSectionProps {
  titleAsH1?: boolean;
}

const VideoSection = forwardRef<HTMLDivElement, VideoSectionProps>(
  ({ titleAsH1 }, ref) => (
    <ScrollToSectionWrapper ref={ref}>
      <div className="flex flex-col gap-x-11 gap-y-12 px-4 tablet:px-5 laptop:flex-row laptop:px-8 desktop:gap-x-16 max:gap-x-20 max:px-[120px]">
        <div className="flex w-full flex-initial flex-col gap-y-5 laptop:flex-1 laptop:gap-y-2.5">
          <h2 className="text-2xl font-black laptop:hidden">
            Lernen Sie unseren Solarrechner kennen
          </h2>
          <WithYoutubeConsent>
            <iframe
              className="aspect-video w-full"
              src="https://www.youtube-nocookie.com/embed/IGjEQtwc__g?rel=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            />
          </WithYoutubeConsent>
        </div>
        <div className="flex flex-1 flex-col gap-y-2 laptop:gap-y-2.5">
          <Headline type={titleAsH1 ? "h1" : "h2"}>
            Was ist ein Solarrechner?
          </Headline>
          <span className="text-base font-normal">
            Ein Solaranlagen-Rechner, kurz Solarrechner, ist ein innovatives
            Online-Tool, das Ihnen einen{" "}
            <span className="font-black">Preisvergleich für Solaranlagen</span>{" "}
            unterschiedlicher Montagefirmen und Hersteller liefert. Sie müssen{" "}
            <span className="font-black">
              lediglich einige Daten über das Gebäude angeben,
            </span>{" "}
            das mit einer Photovoltaikanlage ausgestattet werden soll. Zudem
            liefert Ihnen ein Solarrechner eine Wirtschaftlichkeitsanalyse – so
            können Sie eine fundierte Entscheidung treffen und die Installation
            Ihrer neuen Solaranlage auf dem Dach Ihres Eigenheims in Angriff
            nehmen.
          </span>
        </div>
      </div>
    </ScrollToSectionWrapper>
  ),
);

VideoSection.displayName = "VideoSection";
VideoSection.defaultProps = {
  titleAsH1: false,
};

export default VideoSection;
