import { cn } from "@/lib/utils";

interface RoundedIconProps {
  children: React.ReactNode;
  className?: string;
}

export function RoundedIcon({ children, className }: RoundedIconProps) {
  return (
    <div
      className={cn("rounded-full bg-primary-200 p-2 laptop:p-3", className)}
    >
      {children}
    </div>
  );
}
