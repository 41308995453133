"use client";

import { MouseEventHandler, useState } from "react";

import Icon, { type IconName } from "@/app/_components/icon";
import { twMerge } from "tailwind-merge";

interface ExpandableProps {
  children: React.ReactNode;
  className?: string;
  summaryClassName?: string;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  defaultOpen?: boolean;
  openIcon?: IconName;
  closedIcon?: IconName;
  iconClassName?: string;
  onlyRenderChildrenOnOpen?: boolean;
  noPaddingBottomWhenClosed?: boolean;
}

const Expandable = ({
  children,
  className = "",
  summaryClassName = "",
  title,
  subTitle = undefined,
  defaultOpen = false,
  openIcon = undefined,
  closedIcon = undefined,
  iconClassName = undefined,
  onlyRenderChildrenOnOpen = false,
  noPaddingBottomWhenClosed = false,
}: ExpandableProps) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleToggle: MouseEventHandler<HTMLElement> = event => {
    event.preventDefault();
    setOpen(current => !current);
  };

  return (
    <details
      className={twMerge("group w-full text-text", className)}
      open={open}
    >
      <summary
        className={twMerge(
          `flex list-none items-start justify-between gap-4 py-4 text-lg font-extrabold [&::-webkit-details-marker]:hidden ${noPaddingBottomWhenClosed && !open ? "pb-0" : ""}`,
          summaryClassName,
        )}
        role="button"
        tabIndex={0}
        onClick={handleToggle}
      >
        <div className="flex flex-row items-center gap-x-2 overflow-hidden laptop:block">
          <span>{title}</span>
          <span
            className={`block overflow-hidden text-ellipsis ${
              open ? "pt-1.5" : ""
            } group-open:hidden`}
          >
            {subTitle}
          </span>
        </div>
        <Icon
          className={twMerge(
            "size-6 flex-none self-start cosma:text-weak",
            iconClassName,
          )}
          name={open ? openIcon || "chevron-up" : closedIcon || "chevron-down"}
        />
      </summary>
      {onlyRenderChildrenOnOpen ? open && children : children}
    </details>
  );
};

export default Expandable;
