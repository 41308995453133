import Image, { StaticImageData } from "next/image";

interface ImageSet {
  mobile: StaticImageData;
  desktop: StaticImageData;
}

interface StepCardProps {
  step: number;
  title: React.ReactNode;
  children: React.ReactNode;
  src: ImageSet;
}

export default function MockupListElement({
  step,
  title,
  children,
  src,
}: StepCardProps) {
  return (
    <div className="flex flex-col gap-y-3">
      <div
        className="flex items-center justify-between gap-x-5 bg-neutral-50 px-3 pt-5
                      tablet:px-5
                      laptop:gap-x-11 laptop:px-8 laptop:py-6
                      desktop:gap-x-16 desktop:p-8
                      max:gap-x-20"
      >
        <div className="w-1/2 self-start tablet:w-2/3 ">
          <div className="flex flex-col items-baseline gap-x-4 gap-y-1.5 text-tertiary-500 tablet:flex-row laptop:flex-col desktop:flex-row">
            <div className="text-4xl font-black laptop:text-5xl">{step}</div>
            <div className="hyphens-auto text-xl font-extrabold laptop:text-3xl laptop:font-black">
              {title}
            </div>
          </div>
          <div className="hidden pb-5 pt-1.5 tablet:block laptop:pt-2">
            {children}
          </div>
        </div>
        <Image
          className="block self-end justify-self-end laptop:hidden"
          src={src.mobile}
          alt="example"
          height={240}
        />
        <Image
          width={450}
          className="hidden laptop:block laptop:w-[28.5rem]"
          src={src.desktop}
          alt="example"
        />
      </div>
      <div className="block tablet:hidden">{children}</div>
    </div>
  );
}
