"use client";

import Button from "@/app/_components/button";
import { cn } from "@/lib/utils";
import ArticleTeaser from "@/app/_components/ArticleTeaser/ArticleTeaser";
import { CarouselContent } from "../Carousel/CarouselContent/CarouselContent";
import { CarouselItems } from "../Carousel/CarouselItems";
import { Carousel } from "../Carousel/Carousel";
import { PageControlController } from "../Carousel/PageControlController/PageControlController";
import { useCarousel } from "../Carousel/hooks/useCarousel";
import PageControlElement from "../Carousel/PageControl/PageControlElement";
import Icon from "../icon";
import { CarouselItem } from "../Carousel/CarouselItem/CarouselItem";

const PageControls = ({
  teaserItems,
}: {
  teaserItems: SolarwissenChannelEntryItem[];
}) => {
  const { scrollTo, selectedIndex, scrollSnaps } = useCarousel();
  return (
    <PageControlController
      size={teaserItems.length}
      className="pt-6 max-laptop:pb-7 laptop:justify-end laptop:pt-10"
    >
      <div className="flex gap-[14px] laptop:hidden">
        {scrollSnaps.map((_, index) => (
          <PageControlElement
            key={teaserItems[index].title}
            onClick={() => scrollTo(index)}
            isActive={index === selectedIndex}
          />
        ))}
      </div>
      <div className="hidden gap-6 laptop:flex">
        <Button
          variant="secondary"
          className={cn("!size-12 !p-0", {
            "border-text-500": selectedIndex === 0,
          })}
          onPress={() => scrollTo(selectedIndex - 1)}
          isDisabled={selectedIndex === 0}
        >
          <Icon
            name="arrow-left"
            className={cn("size-6", {
              "text-text-500": selectedIndex === 0,
            })}
          />
        </Button>
        <Button
          variant="secondary"
          className={cn("!size-12 !p-0", {
            "border-text-500": selectedIndex === scrollSnaps.length - 1,
          })}
          onPress={() => scrollTo(selectedIndex + 1)}
          isDisabled={selectedIndex === scrollSnaps.length - 1}
        >
          <Icon
            name="arrow-right"
            className={cn("size-6", {
              "text-text-500": selectedIndex === scrollSnaps.length - 1,
            })}
          />
        </Button>
      </div>
    </PageControlController>
  );
};

export default function KnowledgeSectionCarousel({
  solarwissenArticles,
}: {
  solarwissenArticles: SolarwissenChannelEntryItem[];
}) {
  const teaserItems = solarwissenArticles.map(article => {
    let src = "";
    const srcRegex = /<img[^>]+src="([^">]+)"/i;
    const matchSrc = article.description.match(srcRegex);

    if (matchSrc) {
      // eslint-disable-next-line prefer-destructuring
      src = matchSrc[1];
    }
    return {
      href: article.link,
      src,
      alt: article.title,
      title: article.title,
    };
  });

  return (
    <Carousel
      className="w-full desktop:max-w-[calc(100%-350px)] max:max-w-[calc(100%-405px)]"
      orientation="horizontal"
      opts={{
        slidesToScroll: "auto",
        containScroll: "trimSnaps",
        align: "start",
        dragFree: false,
      }}
    >
      <CarouselContent className="overflow-hidden tablet:mr-1 desktop:last:mr-8 max:last:mr-0">
        <CarouselItems className="desktop:first:ml-4">
          {teaserItems.map(teaserItem => (
            <CarouselItem
              className="tablet:max-w-[22rem] laptop:!max-w-96 max:max-w-[29rem]"
              key={teaserItem.alt}
            >
              <ArticleTeaser
                image={teaserItem.src}
                imageAlt={teaserItem.alt}
                title={teaserItem.title}
                linkTo={teaserItem.href}
                className="mr-4 tablet:mr-0"
              />
            </CarouselItem>
          ))}
        </CarouselItems>
        <PageControls teaserItems={solarwissenArticles} />
      </CarouselContent>
    </Carousel>
  );
}
