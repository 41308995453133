import Expandable from "@/app/_components/Expandable";
import { twMerge } from "tailwind-merge";

interface FAQExpandableProps {
  className?: string;
  children: React.ReactNode;
  title: string;
  defaultOpen?: boolean;
}

const FAQExpandable: React.FC<FAQExpandableProps> = ({
  defaultOpen = true,
  className,
  children,
  title,
}) => (
  <Expandable
    defaultOpen={defaultOpen}
    className={twMerge("border-b pb-4 pt-2", className)}
    title={title}
  >
    {children}
  </Expandable>
);

export default FAQExpandable;
